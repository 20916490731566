export enum PlatformType {
  Shopify = 'SHOPIFY',
  WooCommerce = 'WOOCOMMERCE',
}

export type StoreResource = {
  id: number
  platform_type: PlatformType
  url: string
  title: string
  is_active: boolean
  access_token: string
  created_at: string //ISO date string
  updated_at: string //ISO date string
  is_using_custom_shipping_profile: boolean
}

export type StoreForm = {
  title?: string
  is_using_custom_shipping_profile?: boolean
}

export const getPlatformSmallLogoUrl = (platformType: PlatformType) => {
  let fileName = ''
  switch (platformType) {
    case PlatformType.Shopify:
      fileName = 'shopify-logo-icon'
      break
    case PlatformType.WooCommerce:
      fileName = 'woocommerce-logo-icon'
      break
  }

  return `dropshipping-stores/${fileName}.svg`
}

export const platformTypeDisplayValue = (platformType: PlatformType) => {
  switch (platformType) {
    case PlatformType.Shopify:
      return 'Shopify'
    case PlatformType.WooCommerce:
      return 'Woo Commerce'
    default:
      return ''
  }
}
