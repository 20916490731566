<template>
  <router-link v-if="active" v-bind="$attrs" :to="to">
    <slot></slot>
  </router-link>
  <a v-else v-bind="$attrs" href="#" @click.prevent="emit('disabled-click')">
    <slot></slot>
  </a>
</template>

<script setup lang="ts">
import { RouteLocationRaw } from 'vue-router'

defineProps<{ active: boolean; to: RouteLocationRaw }>()

const emit = defineEmits<{ 'disabled-click': [] }>()
</script>
