import { getOrder } from '@/Api/Order'
import { OrderResource, OrderStatus } from '@/Types/Order'
import { defineStore } from 'pinia'

type State = {
  order: OrderResource<'customer' | { order_items: 'product' }> | null
  errors: any
  statuses: any
}

export const useOrderStore = defineStore('order', {
  state: (): State => ({
    order: null,
    errors: null,
    statuses: {
      PAYMENT_PENDING: OrderStatus.PAYMENT_PENDING,
      FILE_SENT: OrderStatus.FILE_SENT,
    },
  }),
  actions: {
    async setOrder(id: string) {
      try {
        const order = (await getOrder(id)) as unknown as OrderResource<
          'customer' | { order_items: 'product' }
        >

        if (order) {
          this.setOrderState(order)
        }
      } catch (e) {
        this.setOrderState(null)
      }
    },
    clearOrder() {
      this.setOrderState(null)
    },
    setOrderState(payload: State['order']) {
      this.order = payload

      if (payload) {
        localStorage.setItem('order', JSON.stringify(payload))
      }
    },
  },
})
