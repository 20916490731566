<template>
  <component
    :is="tag"
    class="badge"
    :class="[variant ? `badge-${variant}` : '']"
    data-testid="badge"
    v-bind="{ style }"
  >
    <slot></slot>
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    tag?: 'span' | 'p' | 'div'
    variant:
      | 'white'
      | 'lightgray'
      | 'gray'
      | 'darkgray'
      | 'gray-black'
      | 'bright-green'
      | 'dark-green'
      | 'light-green'
      | 'blue'
      | 'darkblue'
      | 'lightblue'
      | 'orange'
      | 'red'
      | 'lightpink'
      | 'darkpink'
    paddingSize?: 'sm' | 'md'
    borderRadiusSize?: 'sm' | 'full'
  }>(),
  { tag: 'span', paddingSize: 'sm', borderRadiusSize: 'sm' },
)

const style = computed(() => ({
  padding: { sm: '.1rem 0.6rem', md: '.4rem 1rem' }[props.paddingSize],
  borderRadius: { sm: '6px', full: '100px' }[props.borderRadiusSize],
}))
</script>

<style scoped lang="scss">
.badge {
  font-size: 1rem;
  line-height: 160%;
  font-weight: 700;
  text-transform: uppercase;
  border: 1px solid;
  margin-right: 4px;
  display: inline-flex;
  align-items: center;
  gap: 0.4rem;

  &-white {
    background: white;
    color: $gray-7;
    border-color: $gray-8;
  }

  &-lightgray {
    color: $gray-3;
    background: $gray-4;
    border-color: $gray-4;
  }

  &-gray {
    color: white;
    background: $gray-1;
    border-color: $gray-1;
  }

  &-darkgray {
    color: white;
    background: $gray-7;
    border-color: $gray-7;
  }

  &-gray-black {
    color: $black;
    background: $light-gray;
    border-color: $light-gray;
  }

  &-bright-green {
    color: $dark-green;
    background: $very-light-green;
    border-color: $very-light-green;
  }

  &-light-green {
    color: $darkest-green;
    background: $lighter-green;
    border-color: $lighter-green;
  }

  &-dark-green {
    color: $lighter-green;
    background: $darkest-green;
    border-color: $darkest-green;
  }

  &-blue {
    color: $dark-blue;
    background: $lightest-blue;
    border-color: $lightest-blue;
  }

  &-lightblue {
    color: $deep-navy;
    background: $pale-sky-blue;
    border-color: $pale-sky-blue;
  }

  &-darkblue {
    color: white;
    background: $twilight-blue;
    border-color: $twilight-blue;
  }

  &-orange {
    color: white;
    background: $orange;
  }

  &-red {
    color: $maroon;
    background: $lightest-red;
    border-color: $lightest-red;
  }

  &-lightpink {
    color: $oxblood;
    background: $light-peach;
    border-color: $light-peach;
  }

  &-darkpink {
    color: white;
    background: $sandy-pink;
    border-color: $sandy-pink;
  }

  :deep(svg) {
    height: 1.3rem;
    width: 1.3rem;
  }
}
</style>
