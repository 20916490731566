<template>
  <Modal class="sign-up" :open="open" @close="close">
    <template #middle>
      <SignInForm
        :login="login"
        :no-redirect="noRedirect"
        :redirect-url="redirectUrl"
        @successful-login="close"
      />
    </template>
  </Modal>
</template>

<script setup lang="ts">
import Modal from './Modal.vue'
import { RouteLocationRaw } from 'vue-router'
import SignInForm from './SignInForm.vue'

withDefaults(
  defineProps<{
    open: boolean
    login: boolean
    redirectUrl?: RouteLocationRaw | null
    noRedirect?: boolean
  }>(),
  {
    redirectUrl: null,
    noRedirect: false,
  },
)

const emit = defineEmits<{ close: [] }>()

const close = () => {
  emit('close')
}
</script>

<style lang="scss">
.sign-up {
  .overlay {
    background: none;
    @media (min-width: 768px) {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  .top {
    border: none;
    padding: 0;
  }

  .bottom {
    display: none;
  }

  .inner {
    top: 6rem;
    transform: translateX(-50%);
    border-radius: 0;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100 - 6rem);
    @media (min-width: 768px) {
      border-radius: 20px;
      height: auto;
      transform: translate(-50%, -50%);
      top: 50%;
      max-width: 440px;
    }
  }

  .middle {
    padding: 4rem 0 7.2rem 0;

    .button-wrap {
      margin: 2.8rem 0 3.2rem 0;
    }
  }

  input[type='text'],
  input[type='password'],
  input[type='email'] {
    background: $gray-6;
  }
}
</style>
