import { defineStore } from 'pinia'
import { ref } from 'vue'

export default defineStore('footer', () => {
  /** #F2F3F5 = $$lighter-gray scss variable */
  const defaultPreFooterBackground = '#F2F3F5'

  const preFooterBackground = ref<'#F2F3F5' | 'white'>(
    defaultPreFooterBackground,
  )

  const showSeoSection = ref<boolean>(false)

  const toggleShowSeoSection = (isVisible: boolean) => {
    showSeoSection.value = isVisible
    if (isVisible) {
      preFooterBackground.value = 'white'
    } else {
      resetPreFooterBackground()
    }
  }

  const resetPreFooterBackground = () => {
    preFooterBackground.value = defaultPreFooterBackground
  }

  return {
    preFooterBackground,
    resetPreFooterBackground,
    toggleShowSeoSection,
    showSeoSection,
  }
})
