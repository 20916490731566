<template>
  <div class="preferences-dropdown-mobile">
    <div v-if="globalStore.countries && currencies" class="country-currency">
      <div class="input-element">
        <label for="country">{{ __('global', 'Country') }}</label>
        <v-select
          id="country"
          v-model="country"
          :clearable="false"
          label="title"
          :options="globalStore.countries"
          :reduce="selectReduce"
          @option:selected="updateCurrency"
        >
          <template #open-indicator>
            <SvgIcon class="open-chevron" name="chevron" />
          </template>
          <template #option="{ code, title }">
            <p class="country-option">
              <svg>
                <use
                  :xlink:href="`/images/flags.svg#sprite-${code.toLowerCase()}`"
                />
              </svg>
              <span>{{ title }}</span>
            </p>
          </template>
          <template #selected-option="{ code, title }">
            <p class="country-option">
              <svg>
                <use
                  :xlink:href="`/images/flags.svg#sprite-${code.toLowerCase()}`"
                />
              </svg>
              <span>{{ title }}</span>
            </p>
          </template>
        </v-select>
      </div>
      <div class="input-element">
        <label for="currency">{{ __('global', 'Currency') }}</label>
        <v-select
          id="currency"
          v-model="currency"
          :clearable="false"
          label="name"
          :options="currencies"
          :reduce="selectReduce"
        >
          <template #open-indicator>
            <SvgIcon class="open-chevron" name="chevron" />
          </template>
        </v-select>
      </div>
    </div>
    <div v-if="globalStore.locales" class="input-element">
      <label for="locale">{{ __('header', 'Language') }}</label>
      <v-select
        id="locale"
        v-model="locale"
        label="name"
        :options="globalStore.locales"
        :reduce="addressSelectReduce"
      >
        <template #open-indicator>
          <SvgIcon class="open-chevron" name="chevron" />
        </template>
      </v-select>
    </div>
    <div class="payment-methods">
      <p>{{ __('header', 'Accepted payment methods') }}</p>
      <PaymentMethodsIcons />
    </div>
  </div>
</template>

<script setup lang="ts">
import PaymentMethodsIcons from '@/Partials/PaymentMethodsIcons.vue'
import SvgIcon from '../SvgIcon.vue'
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { __ } from '@/Helpers/i18n'
import { addressSelectReduce, getCurrentSessionValue } from '@/Helpers/Global'
import { useGlobalStore } from '@/Store/useGlobalStore'
import { CountryResource, CountryResourceMainFields } from '@/Types/Country'
import { CurrencyResource } from '@/Types/Currency'
import useLangStore from '@/Store/useLangStore'

const globalStore = useGlobalStore()
const langStore = useLangStore()
const route = useRoute()

const country = ref(getCurrentSessionValue('CURRENT_COUNTRY').id)
const currency = ref(getCurrentSessionValue('CURRENT_COUNTRY').currency.id)
const locale = ref(langStore.getLang())
const currencies = ref([getCurrentSessionValue('CURRENT_COUNTRY').currency])

const loading = ref(false)
const updatePreferences = async () => {
  loading.value = true
  try {
    await globalStore.putSessionSetting({
      id: 'CURRENT_COUNTRY',
      value: country.value,
    })

    localStorage.setItem('locationPopupCountry', country.value)
    window.location.href =
      langStore.getLang() === locale.value ? route.fullPath : `/${locale.value}`
  } catch (e) {
    console.error(e)
  } finally {
    loading.value = false
  }
}

watch([country, currency, locale], () => {
  if (country.value && currency.value && locale.value) {
    updatePreferences()
  }
})

const selectReduce = (item: CurrencyResource | CountryResourceMainFields) =>
  item.id

const updateCurrency = (item: CountryResource<'currency'>) => {
  currencies.value = [item.currency]
  currency.value = item.currency.id
}
</script>

<style lang="scss" scoped>
.preferences-dropdown-mobile {
  width: 100%;
  margin-top: 1.2rem;
  @include desktop-only {
    position: absolute;
    left: 50%;
    transform: translateX(-72%);
    top: 3.4rem;
    z-index: 11;
    width: 302px;
    border: 1px solid $light-gray;
    background: $white;
    padding: 2rem 2rem 0 2rem;
    margin-top: 0;
    @include border-radius(xs);
  }
}

.country-currency {
  display: flex;
  gap: 1.2rem;
  margin-bottom: 1.4rem;
}

.input-element {
  flex: 0 0 calc(50% - 0.8rem / 2);

  label {
    display: inline-block;
    margin-bottom: 1rem;
    letter-spacing: -0.02em;
    line-height: 1.5;
    font-weight: 500;
  }
}

.country-option {
  display: flex;
  align-items: center;

  svg {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    margin-right: 0.8rem;
    flex: none;
  }
}

.payment-methods {
  padding: 3.2rem 2rem 4rem 2rem;
  margin: 4rem -2rem 0 -2rem;
  background: $gray-4;
  text-align: center;

  p {
    font-size: 1.2rem;
    margin-bottom: 1.2rem;
    font-weight: 500;
    letter-spacing: -0.01em;
    line-height: 1.5;
  }
}
</style>

<style lang="scss">
.preferences-dropdown-mobile {
  .v-select {
    background: $white;
    margin-left: -4px;

    .vs__dropdown-toggle {
      background: $white;
      border-radius: 10px;
      border: 1px solid $light-gray !important;
      padding-left: 0.2rem;
      padding-right: 0.2rem;
    }

    .vs__selected-options {
      .vs__selected,
      .vs__search {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
      }

      .vs__search {
        max-width: 125px;
        border: none;
      }

      @include desktop-only {
        .vs__search,
        .vs__selected {
          max-width: 94px;
          overflow: hidden;
        }
      }
    }
  }
}
</style>
