import { useGlobalStore } from '@/Store/useGlobalStore'
import useLangStore from '@/Store/useLangStore'
import { DEFAULT_LANG } from './Constants'

export const redirectToError = () => {
  const globalStore = useGlobalStore()
  const languages = globalStore.locales
  let lang = useLangStore().getLang()
  if (languages && !languages.find(({ code }) => code === lang)) {
    lang = DEFAULT_LANG
  }
  window.location.href = `/${lang}/error`
}
