<template>
  <div class="login">
    <form v-if="inputs" action="" @submit.prevent="loginUser">
      <div
        v-for="item in inputs"
        :key="item.name"
        class="input-element"
        :class="{ error: findErrorField(errors, item.name) }"
      >
        <PasswordInput
          v-if="item.type === 'password'"
          v-model="item.value"
          class="wrap"
          :input="item"
        />
        <div v-else class="wrap">
          <input
            :id="item.name"
            v-model="item.value"
            placeholder=" "
            :type="item.type"
          />
          <label :for="item.name">{{ item.label }}</label>
        </div>

        <div v-if="errors" class="errors">
          <div v-for="i in errors" :key="i.field">
            <p v-if="i.field === item.name">{{ i.message }}</p>
          </div>
        </div>
      </div>

      <div class="button-wrap">
        <button class="btn btn-primary" :class="{ loading }" type="submit">
          {{ __('global', 'Sign in') }}
        </button>
      </div>

      <div class="forget-wrapper">
        <a
          class="forgot"
          href="#"
          @click.prevent="emit('switch-to-forgot-password')"
        >
          {{ __('profile', 'Forgot password?') }}
        </a>
      </div>

      <div class="register-wrapper">
        {{ __('global', "Don't have an account?") }}
        <button @click.prevent="emit('switch-to-registration')">
          {{ __('global', 'Register') }} <Arrow />
        </button>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { getLoginInputs } from '@/Helpers/Forms'
import { findErrorField } from '@/Helpers/Global'
import { __ } from '@/Helpers/i18n'
import PasswordInput from '@/Partials/PasswordInput.vue'
import { analyticsLogin } from '@/Helpers/Analytics'
import { useLoginStore } from '@/Store/useLoginStore'
import { useCartStore } from '@/Store/useCartStore'
import Arrow from '@/Partials/Arrow.vue'

const emit = defineEmits<{
  'switch-to-registration': []
  'redirect-after-login': []
  'switch-to-forgot-password': []
}>()

const loginStore = useLoginStore()
const cartStore = useCartStore()

const errors = computed(() => loginStore.errors)

const inputs = ref(getLoginInputs())
const loading = ref(false)

const loginUser = async () => {
  loading.value = true
  const data: Record<string, unknown> = {}

  inputs.value.forEach((item) => (data[item.name] = item.value))

  await loginStore.postLogin(data)
  loading.value = false

  if (!errors.value) {
    await cartStore.initializeCartIfAuthenticated()
    analyticsLogin()
    emit('redirect-after-login')
  }
}
</script>
