import { useOrderStore } from '@/Store/useOrderStore'
import { useCartStore } from '@/Store/useCartStore'

export const getOrderFromLocal = async () => {
  const orderStore = useOrderStore()
  let order = orderStore.order

  if (!order) {
    const localStorageOrder = localStorage.getItem('order')
    if (localStorageOrder) {
      const orderParsed = JSON.parse(localStorageOrder)
      if (
        orderParsed &&
        typeof orderParsed === 'object' &&
        'reference' in orderParsed &&
        typeof (orderParsed as { reference: any }).reference === 'string'
      ) {
        await orderStore.setOrder(
          (orderParsed as { reference: string }).reference,
        )
        order = orderStore.order
      }
    }
  }

  return order
}

export const removeOrder = async () => {
  const orderStore = useOrderStore()
  const cartStore = useCartStore()

  const localStorageOrder = localStorage.getItem('order')
  if (localStorageOrder) {
    localStorage.removeItem('order')
  }

  orderStore.clearOrder()
  await cartStore.initializeCartIfAuthenticated()
}
