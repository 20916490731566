import axios from 'axios'
import useLangStore from '@/Store/useLangStore'
import { SeoBlockResource } from '@/Types/SeoBlock'

export const getSeoBlocks = async (): Promise<SeoBlockResource[] | null> => {
  try {
    return (await axios.get(`/${useLangStore().getLang()}/seo-blocks`)).data
  } catch (e) {
    console.error(e)
    return null
  }
}

export const getSeoBlock = async (
  id: number,
): Promise<SeoBlockResource | null> => {
  try {
    return (await axios.get(`/${useLangStore().getLang()}/seo-blocks/${id}`))
      .data
  } catch (e) {
    console.error(e)
    return null
  }
}
