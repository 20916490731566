<template>
  <a
    v-if="urlPath.startsWith('http')"
    :href="urlPath"
    :target="targetBlank ? '_blank' : '_self'"
  >
    <slot></slot>
  </a>
  <router-link v-else :to="{ path: urlPath, query: urlQuery, force: true }"
    ><slot></slot
  ></router-link>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{ url: string; targetBlank?: boolean }>()

const urlPath = computed(() =>
  props.url.includes(location.origin)
    ? props.url.replace(location.origin, '')
    : props.url,
)

const urlQuery = computed(() =>
  Object.fromEntries(new URL(props.url, location.origin).searchParams),
)
</script>
