import { getStaticContentDomain, isSvgSrc } from '@/Helpers/Global'

const formatOptions = (params: Record<string, string | number | boolean>) => {
  return Object.entries(params)
    .map(([key, value]) => `${key}=${value}`)
    .join('&')
}

/** @see https://developers.cloudflare.com/images/image-resizing/url-format#options */
export type CloudflareImageOptions = {
  width: number
  image: string
}

/** @see https://developers.cloudflare.com/images/image-resizing/url-format */
export const createCloudflareImageUrl = (options: CloudflareImageOptions) => {
  if (isSvgSrc(options.image)) {
    return `${getStaticContentDomain()}${options.image}`
  }

  const encodedOptions = btoa(formatOptions(options))

  return `${getStaticContentDomain()}/r/${encodedOptions}`
}
