import axios from 'axios'
import {
  CountryResource,
  CountryResourceExpandableProperties,
  CountryState,
} from '@/Types/Country'
import { SiteTreeSectionResource } from '@/Types/SiteTreeSection'
import useLangStore from '@/Store/useLangStore'
import { FooterLinkResource } from '@/Types/FooterLink'

export const getLocales = async () => (await axios.get(`/locales`)).data

export const getSiteTreeSections = async () =>
  (
    await axios.get<SiteTreeSectionResource[]>(
      `/${useLangStore().getLang()}/site-tree-sections`,
      {
        params: {
          'per-page': '-1',
          expand: 'section_best_seller_config.product_attributes',
        },
      },
    )
  ).data

export const getHeaderMenu = async () =>
  (await axios.get(`/${useLangStore().getLang()}/header-menu-items`)).data

export const getFooterLinks = async (): Promise<FooterLinkResource[]> =>
  (
    await axios.get(`/${useLangStore().getLang()}/footer-links`, {
      params: { 'per-page': '-1' },
    })
  ).data

export const getTranslations = async () =>
  (await axios.get(`/${useLangStore().getLang()}/static-translations`)).data

export const getSessionSettings = async () =>
  (await axios.get(`/${useLangStore().getLang()}/session-settings`)).data

export const getCountries = async <
  T extends CountryResourceExpandableProperties,
>(
  expandArray: readonly T[] = [],
) => {
  const response = await axios.get<CountryResource<T>[]>(
    `/${useLangStore().getLang()}/countries`,
    { params: { 'per-page': '-1', expand: expandArray.join(',') } },
  )
  return response.data
}

export const getSettings = async () =>
  (await axios.get(`/${useLangStore().getLang()}/settings`)).data

export const getUSAStates = async (): Promise<CountryState[]> =>
  (
    await axios.get(`/${useLangStore().getLang()}/states`, {
      params: { 'per-page': '-1' },
    })
  ).data
