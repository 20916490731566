import axios from 'axios'
import { StoreProductForm, StoreProductResource } from '@/Types/StoreProduct'
import { fileUrlToFile } from '@/Helpers/Global'
import useLangStore from '@/Store/useLangStore'
import { getDesign } from './Design'

export const getStoreProducts = async (
  storeId: number,
  data?: { sort?: string; page?: number; filter?: Record<string, string> },
) => {
  const lang = useLangStore().getLang()

  const params: Record<string, string | number | Record<string, number>> = {
    'per-page': 50,
    filter: { store_id: storeId },
  }
  if (data?.sort) params.sort = data.sort
  if (data?.page) params.page = data.page

  const response = await axios.get<StoreProductResource[]>(
    `/${lang}/store-products`,
    { params },
  )

  return {
    data: response.data,
    page_count: parseInt(response.headers['x-pagination-page-count'] as string),
    page: parseInt(response.headers['x-pagination-current-page'] as string),
  }
}

export const getStoreProduct = async (id: number) => {
  const lang = useLangStore().getLang()
  return axios.get<StoreProductResource>(`/${lang}/store-products/${id}`)
}

export const createStoreProduct = (formData: StoreProductForm) => {
  const lang = useLangStore().getLang()

  return axios.post(`/${lang}/store-products`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  }) as Promise<{ data: StoreProductResource | null }>
}

export const duplicateStoreProduct = async (
  storeProduct: StoreProductResource,
) => {
  const design = await getDesign(storeProduct.design_id!)

  if (!design) return

  const formData = {
    title: `Copy of ${storeProduct.title}`,
    store_id: storeProduct.store_id,
    design_id: design.id,
    product_id: storeProduct.product_id,
    price: storeProduct.price,
    tags: JSON.stringify(storeProduct.tags),
    description: storeProduct.description,
    images: await Promise.all(
      storeProduct.images.map((imageUrl, i) =>
        fileUrlToFile(imageUrl, `mockup-image-${i}`),
      ),
    ),
  } satisfies StoreProductForm

  return createStoreProduct(formData)
}

export const updateStoreProduct = async (
  id: number,
  formData: Partial<StoreProductForm>,
) => {
  const lang = useLangStore().getLang()
  return (
    await axios.patch<StoreProductResource>(
      `/${lang}/store-products/${id}`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } },
    )
  ).data
}

export const deleteStoreProduct = (id: number) => {
  const lang = useLangStore().getLang()
  return axios.delete(`/${lang}/store-products/${id}`)
}
