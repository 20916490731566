import { defineStore } from 'pinia'
import { computed } from 'vue'
import { __ } from '@/Helpers/i18n'
import { useGlobalStore } from '@/Store/useGlobalStore'
import { useProfileStore } from '@/Store/useProfileStore'
import { withLang } from '@/routes'
import { useLoginStore } from '@/Store/useLoginStore'
import useScreenBreakpoints from '@/Composables/UseScreenBreakpoints'
import { useCartStore } from '@/Store/useCartStore'

const createMenuItem = (
  to: string | { name: string; params: { lang: string } },
  icon: string,
  label: string,
  meta?: {
    hasNotification?: boolean
    hideOnDesktop?: boolean
    isNew?: boolean
    itemCount?: number
  },
) => ({ to, icon, label, meta })

export default defineStore('profile-menu', () => {
  const globalStore = useGlobalStore()
  const profileStore = useProfileStore()
  const loginStore = useLoginStore()
  const cartStore = useCartStore()
  const { isMobile } = useScreenBreakpoints()

  const productsUrl = computed(() => globalStore.productsUrl)
  const servicesUrl = computed(() => globalStore.servicesUrl)
  const profileUnreadOrdersCount = computed(
    () => profileStore.profileUnreadOrdersCount,
  )

  const profileMenuItems = computed(() =>
    [
      createMenuItem(
        withLang({ name: 'profile-dashboard' }),
        'grid',
        __('profile', 'Dashboard'),
      ),
      createMenuItem(
        withLang({ name: 'my-products' }),
        'edit',
        __('products', 'My Products'),
        { isNew: true },
      ),
      loginStore.isDropshippingEnabled
        ? createMenuItem(
            withLang({ name: 'dropshipping-stores' }),
            'shopping-bag',
            __('dropshipping-stores', 'My stores'),
          )
        : null,
      createMenuItem(
        withLang({ name: 'profile-orders' }),
        'list',
        __('profile', 'Orders'),
        { hasNotification: !!profileUnreadOrdersCount.value },
      ),
      createMenuItem(
        withLang({ name: 'profile-files' }),
        'folder',
        __('profile', 'File library'),
      ),
      isMobile.value
        ? createMenuItem(
            withLang({ name: 'profile-details' }),
            'edit',
            __('header', 'navigation.mobile-profile.account-settings.link'),
          )
        : null,
    ].filter(Boolean),
  )

  const mainMenuItems = computed(() => {
    const res = [
      createMenuItem(
        withLang({ name: 'profile-details' }),
        'profile',
        __('global', 'My Profile'),
        { hideOnDesktop: true },
      ),
      createMenuItem(
        withLang({ name: 'home' }),
        'house',
        __('profile', 'Homepage'),
      ),
    ]
    if (productsUrl.value) {
      res.push(
        createMenuItem(productsUrl.value, 'cart', __('global', 'Products')),
      )
    }
    if (servicesUrl.value) {
      res.push(
        createMenuItem(servicesUrl.value, 'wand', __('profile', 'Services')),
      )
    }
    if (isMobile.value) {
      res.push(
        createMenuItem(
          withLang({ name: 'cart' }),
          'cart',
          __('global', 'My bag'),
          { itemCount: cartStore.cartItemQuantity },
        ),
      )
    }

    return res
  })

  return { profileMenuItems, mainMenuItems }
})
