export enum ProductDescriptionType {
  USP = 'USP',
  PRODUCTION = 'PRODUCTION',
  DELIVERY = 'DELIVERY',
}

export type ProductDescriptionResource = {
  id: number
  type: ProductDescriptionType
  title: string | null
  description: string | null
}
