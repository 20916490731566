import { getProductCategories } from '@/Api/Products'
import { defineStore } from 'pinia'
import { ProductCategoryResource } from '@/Types/ProductCategory'

export const useProductsStore = defineStore('products', {
  state: (): { productCategories: ProductCategoryResource[] | null } => ({
    productCategories: null,
  }),
  actions: {
    async setProductCategories() {
      this.productCategories = await getProductCategories()
    },
  },
})
