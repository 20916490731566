<template>
  <div v-if="open" class="modal">
    <div class="overlay" @click="closeModal"></div>
    <div class="inner">
      <div v-if="!hideCloseButton" class="close-modal" @click="closeModal">
        <SvgIcon name="exit" />
      </div>
      <div class="top">
        <slot name="top"></slot>
      </div>
      <div class="middle" @scroll="(e) => emit('middle-scrolled', e)">
        <slot name="middle"></slot>
      </div>
      <div v-if="!hideBottom" class="bottom">
        <slot name="bottom"></slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import SvgIcon from '@/Partials/SvgIcon.vue'
import { watch } from 'vue'
import { toggleBodyCssClass, SCROLL_LOCK_CSS_CLASS } from '@/Helpers/Global'

const props = defineProps<{
  open: boolean
  hideCloseButton?: boolean
  hideBottom?: boolean
}>()

const emit = defineEmits<{
  close: []
  'middle-scrolled': [event: Event]
}>()

watch(
  () => props.open,
  (isOpen) => {
    toggleBodyCssClass(SCROLL_LOCK_CSS_CLASS, isOpen)
  },
)

const closeModal = () => emit('close')
</script>

<style lang="scss">
.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 11;

  .overlay {
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  .inner {
    background: $white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    box-shadow: 0 20px 48px rgba(6, 24, 68, 0.1);
    @include border-radius(lg);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    max-width: 1064px;
    max-height: 95vh;
    @media (min-width: 768px) {
      width: 100%;
    }
  }

  .close-modal {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    color: $black;
    z-index: 1;

    svg {
      width: 12px;
      height: 12px;
    }
  }

  .top {
    padding: 3.5rem 10rem 2.5rem 10rem;
    border-bottom: 1px solid $lighter-gray;
    text-align: center;
  }

  .middle {
    padding: 3.2rem 1.6rem;
    @include desktop-only {
      padding: 3.2rem 10rem;
    }
  }

  .bottom {
    border-top: 1px solid $lighter-gray;
    padding: 3.6rem 1.6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include desktop-only {
      padding: 3.6rem 10rem;
    }
  }

  .button-wrap {
    display: flex;
    justify-content: center;
    margin-top: 3.4rem;
    flex-direction: column;
    width: 100%;

    @include desktop-only {
      flex-direction: row;
    }

    .btn {
      margin: 0.9rem 0;
      width: 100%;
      @include desktop-only {
        margin: 0 0.9rem;
        width: 200px;
      }
    }
  }
}
</style>
