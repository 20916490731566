<template>
  <BaseBlock :data="data">
    <ul class="links-list">
      <li v-for="link in links" :key="link.url">
        <Link
          class="link"
          :class="'variant' in data ? data.variant : ''"
          target-blank
          :url="link.url"
          >{{ link.text }}</Link
        >
      </li>
    </ul>
  </BaseBlock>
</template>

<script setup lang="ts">
import { NavigationBlock } from '@/Partials/MainNavigation/navigationConfig'
import BaseBlock from '@/Partials/MainNavigation/BaseBlock.vue'
import { computed } from 'vue'
import { useGlobalStore } from '@/Store/useGlobalStore'
import Link from '@/Partials/Link.vue'
import useLangStore from '@/Store/useLangStore'

const props = defineProps<{ data: NavigationBlock<'PLAIN_LINKS'> }>()

const globalStore = useGlobalStore()
const langStore = useLangStore()

const links = computed(() =>
  'sectionTypes' in props.data
    ? props.data.sectionTypes
        .map((type) => {
          const siteTreeItem = globalStore.siteTree!.find(
            (s) => s.section_type === type,
          )
          if (!siteTreeItem) return
          return {
            url: `/${langStore.getLang()}${siteTreeItem.slug}`,
            text: siteTreeItem.title,
          }
        })
        .filter(Boolean)
    : 'links' in props.data
      ? props.data.links
      : [],
)
</script>

<style scoped lang="scss">
.links-list {
  padding: 0 0.8rem;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  @include desktop-only {
    padding: 0 0.4rem;
    gap: 1.6rem;
  }
}

.link {
  color: $black;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: -0.02em;

  &.lg {
    font-size: 1.8rem;
    line-height: 1.4;
  }
}
</style>
