import { DesignResource } from '@/Types/Design'
import ProductVM, { ProductVMType } from './ProductVM'
import { CartItemResource } from '@/Types/CartItem'
import { OrderItemResource } from '@/Types/OrderItem'
import { ProductResource } from '@/Types/Product'

export default class OrderItemVM {
  private constructor(
    public id: number,
    public productId: number | null,
    public designId: number | null,
    public quantity: number,
    public total: number | null,
    public product: ProductVMType<
      | 'blacklisted_countries'
      | 'gallery_photos'
      | 'sample_set_products'
      | 'collection_box_products'
    >,
    public design?: DesignResource | null,
    public discount?: string | null,
    public total_discounted?: number | null,
    public is_sample?: boolean | null,
  ) {}

  static createFromOrderItem(
    orderItem: OrderItemResource<'product'>,
  ): OrderItemVM {
    const product = ProductVM.createFrom(
      orderItem.product as unknown as ProductResource<
        | 'blacklisted_countries'
        | 'gallery_photos'
        | 'sample_set_products'
        | 'collection_box_products'
      >,
    )

    return new OrderItemVM(
      orderItem.id,
      orderItem.product_id,
      orderItem.design_id,
      orderItem.quantity,
      orderItem.total,
      product,
      orderItem.design,
      orderItem.discount,
      orderItem.total_discounted,
      orderItem.is_sample,
    )
  }

  static createFromOrderItemWithDesign(
    orderItem: OrderItemResource<'product' | 'design'>,
    countryCode?: string,
  ): OrderItemVM {
    const product = ProductVM.createFrom(
      orderItem.product as unknown as ProductResource<
        | 'blacklisted_countries'
        | 'gallery_photos'
        | 'sample_set_products'
        | 'collection_box_products'
      >,
      countryCode,
    )
    return new OrderItemVM(
      orderItem.id,
      orderItem.product_id,
      orderItem.design_id,
      orderItem.quantity,
      orderItem.total,
      product,
      orderItem.design,
      orderItem.discount,
      orderItem.total_discounted,
      orderItem.is_sample,
    )
  }

  static createFromCartItem(cartItem: CartItemResource<'product' | 'design'>) {
    const product = ProductVM.createFrom(
      cartItem.product as unknown as ProductResource<
        | 'blacklisted_countries'
        | 'gallery_photos'
        | 'sample_set_products'
        | 'collection_box_products'
      >,
    )
    return new OrderItemVM(
      cartItem.id,
      cartItem.product_id,
      cartItem.design_id,
      cartItem.quantity,
      cartItem.total,
      product,
      cartItem.design,
      cartItem.discount,
      cartItem.total_discounted,
      cartItem.is_sample,
    )
  }

  get productImage() {
    return (
      this.design?.mockup_image_url ??
      this.product.image ??
      'profile/order-item-placeholder.jpg'
    )
  }
}
