import axios from 'axios'
import useLangStore from '@/Store/useLangStore'

export const getRegistration = async (data: { id: string; token: string }) =>
  (
    await axios.get(
      `/${useLangStore().getLang()}/registrations/${data.id}/${data.token}`,
    )
  ).data

export const getPasswordReset = async (data: { id: string; token: string }) => {
  const lang = useLangStore().getLang()

  return (await axios.get(`/${lang}/password-resets/${data.id}/${data.token}`))
    .data
}

export const postRegistration = (data: Record<string, unknown>) => {
  const lang = useLangStore().getLang()
  return axios.post(`/${lang}/registrations`, data)
}

export const putRegistration = (data: {
  id: string
  token: string
  data: Record<string, unknown>
}) => {
  const lang = useLangStore().getLang()
  return axios.put(`/${lang}/registrations/${data.id}/${data.token}`, data.data)
}

export const postSocialConfirmation = (data: { id: string; token: string }) => {
  const lang = useLangStore().getLang()
  return axios.post(`/${lang}/social-confirmations/${data.id}/${data.token}`)
}

export const postEmailChangeConfirmation = (data: {
  id: string
  token: string
}) => {
  const lang = useLangStore().getLang()
  return axios.post(
    `/${lang}/email-change-confirmations/${data.id}/${data.token}`,
  )
}

export const postPasswordReset = (data: Record<string, unknown>) => {
  const lang = useLangStore().getLang()
  return axios.post(`/${lang}/password-resets`, data)
}

export const putPasswordReset = (data: {
  id: string
  token: string
  data: Record<string, unknown>
}) => {
  const lang = useLangStore().getLang()
  return axios.put(
    `/${lang}/password-resets/${data.id}/${data.token}`,
    data.data,
  )
}
