import { RouteLocation, Router } from 'vue-router'
import { getAwsFileUrl, getCurrentSessionValue } from './Global'
import { ProductVMType } from '@/ViewModels/ProductVM'

export const initGoogleBreadcrumbs = (
  route: RouteLocation,
  router: Router,
  currentPageData: { title: string; path: string } | null = null,
) => {
  let breadcrumbItems = initBreadcrumbItems(route, router)

  if (currentPageData) {
    breadcrumbItems = [
      ...breadcrumbItems,
      ...[
        {
          '@type': 'ListItem',
          position: breadcrumbItems.length + 1,
          name: currentPageData.title,
          item: `${window.location.origin}${currentPageData.path}`,
        },
      ],
    ]
  }

  const scriptElement = document.createElement('script')
  scriptElement.setAttribute('type', 'application/ld+json')
  const data = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbItems,
  }

  scriptElement.innerText = JSON.stringify(data)
  document.head.appendChild(scriptElement)
}

const initBreadcrumbItems = (route: RouteLocation, router: Router) => {
  const pathArray = route.path.split('/')
  pathArray.shift()
  const routes = router.getRoutes()

  let path = ''
  let position = 1
  const breadcrumbs: any = []
  pathArray.forEach((item) => {
    path += `/${item}`
    for (const i in routes) {
      if (routes[i].path === path) {
        breadcrumbs.push({
          '@type': 'ListItem',
          position,
          name: routes[i].meta.title,
          item: `${window.location.origin}${routes[i].path}`,
        })
        position++
      }
    }
  })

  return breadcrumbs
}

export const initGoogleLogo = () => {
  const scriptElement = document.createElement('script')
  scriptElement.setAttribute('type', 'application/ld+json')
  const data = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: window.location.origin,
    logo: getAwsFileUrl('selfnamed-logo.svg'),
  }
  scriptElement.innerText = JSON.stringify(data)
  document.head.appendChild(scriptElement)
}

export const initGoogleProduct = (
  product: Pick<
    ProductVMType,
    'title' | 'image' | 'code' | 'lowestAvailablePrice'
  >,
) => {
  const scriptElement = document.createElement('script')
  scriptElement.setAttribute('type', 'application/ld+json')

  const data = {
    '@context': 'https://schema.org',
    '@type': 'Product',
    name: product.title,
    image: product.image,
    sku: product.code,
    offers: {
      '@type': 'Offer',
      price: product.lowestAvailablePrice,
      priceCurrency: getCurrentSessionValue('CURRENT_COUNTRY').currency.name,
    },
  }

  scriptElement.innerText = JSON.stringify(data)
  document.head.appendChild(scriptElement)
}
