<template>
  <div class="registration">
    <form v-if="signUpInputs" action="" @submit.prevent="signUpUser">
      <div
        v-for="item in signUpInputs"
        :key="item.name"
        class="input-element"
        :class="{
          error:
            registrationErrors && findErrorField(registrationErrors, item.name),
        }"
      >
        <div class="wrap">
          <input
            :id="item.name"
            v-model="item.value"
            placeholder=" "
            :type="item.type"
          />
          <label :for="item.name">{{ item.label }}</label>
        </div>

        <div v-if="registrationErrors" class="errors">
          <div v-for="i in registrationErrors" :key="i.field">
            <p v-if="i.field === item.name">{{ i.message }}</p>
          </div>
        </div>
      </div>
      <SignUpCheckboxes
        :errors="registrationErrors"
        :newsletter="newsletter"
        newsletter-show
        :terms="terms"
        @update-newsletter="updateNewsletter"
        @update-terms="updateTerms"
      />
      <div class="button-wrap">
        <button class="btn btn-primary" :class="{ loading }" type="submit">
          {{ __('global', 'Sign up') }}
        </button>
      </div>

      <div class="register-wrapper">
        {{ __('global', 'Already have account?') }}
        <button @click.prevent="emit('switch-to-login')">
          {{ __('global', 'Sign in') }} <Arrow />
        </button>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { getSignUpInputs, Input } from '@/Helpers/Forms'
import { findErrorField } from '@/Helpers/Global'
import { __ } from '@/Helpers/i18n'
import { analyticsSignUp } from '@/Helpers/Analytics'
import { postRegistration } from '@/Api/Registration'
import SignUpCheckboxes from './SignUpCheckboxes.vue'
import Arrow from '@/Partials/Arrow.vue'

const emit = defineEmits<{
  'switch-to-login': []
  'switch-to-success': [
    {
      title: string
      description: string
    },
  ]
}>()

const signUpInputs = ref<Input[]>(getSignUpInputs())
const terms = ref(false)
const newsletter = ref(true)
const registrationErrors = ref()
const loading = ref(false)

const signUpUser = async () => {
  loading.value = true
  registrationErrors.value = null
  const data: { [key: string]: any } = {}

  signUpInputs.value.forEach((item) => {
    data[item.name] = item.value
  })

  data.terms_accepted = terms.value
  data.receive_emails = newsletter.value

  await postRegistration(data).catch((error: any) => {
    registrationErrors.value = error.response.data
  })

  loading.value = false

  if (!registrationErrors.value) {
    analyticsSignUp(data)
    emit('switch-to-success', {
      title: 'Thank you for signing up!',
      description: "We've sent you confirmation link to your e-mail address",
    })
  }
}

const updateTerms = (value: boolean) => {
  terms.value = value
}

const updateNewsletter = (value: boolean) => {
  newsletter.value = value
}
</script>
