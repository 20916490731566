import { defineStore } from 'pinia'
import {
  facebookLogin,
  googleLogin,
  login,
  logout,
  setProfile,
} from '@/Api/Profile'
import ProfileVM from '@/ViewModels/ProfileVM'
import { useGlobalStore } from './useGlobalStore'
import { ErrorData, setErrors } from '@/Helpers/Errors'
import { useCartStore } from './useCartStore'
import * as Sentry from '@sentry/vue'

export const useLoginStore = defineStore('login', {
  state: (): {
    errors: any
    profile: ProfileVM | null
    profileLoaded: boolean
  } => ({
    errors: null,
    profile: null,
    profileLoaded: false,
  }),
  getters: {
    isLegalEntityWithVerifiedVat: (state) => {
      if (!state.profile) return false
      return state.profile.legal_entity && state.profile.vatVerified
    },
    hasFilledAllLegalEntityFields(state) {
      if (!state.profile) return false
      return (
        state.profile.company_name &&
        state.profile.country &&
        state.profile.registration_number &&
        state.profile.vat_id &&
        state.profile.vat_certificate
      )
    },
    isDropshippingEnabled(state) {
      if (!state.profile) return false
      const globalStore = useGlobalStore()
      const dropshippingSetting = globalStore.getSettingByKey(
        'dropshippingsetting',
      )
      return (
        !dropshippingSetting || dropshippingSetting.value.isDropShippingEnabled
      )
    },
    userLoggedIn(state) {
      return !!state.profile
    },
  },
  actions: {
    async postLogin(data: Record<string, unknown>) {
      try {
        await login(data)
        await this.setProfile()
        this.errors = null
      } catch (error) {
        this.errors = setErrors(error as ErrorData)
      }
    },
    async postGoogleLogin(token: string) {
      try {
        const response = await googleLogin(token)
        this.profile = ProfileVM.createFrom(response.data)
        this.errors = null
        this.setSentryUser()
      } catch (error) {
        this.errors = setErrors(error as ErrorData)
      }
    },
    async postFacebookLogin(token: string) {
      try {
        const response = await facebookLogin(token)
        this.profile = ProfileVM.createFrom(response.data)
        this.errors = null
        this.setSentryUser()
      } catch (error) {
        this.errors = setErrors(error as ErrorData)
      }
    },
    async logout() {
      try {
        await logout()
        this.resetProfile()
      } catch (e) {
        console.error(e)
      }
    },
    async setProfile() {
      try {
        const response = await setProfile()
        this.profile = ProfileVM.createFrom(response.data)
        this.setSentryUser()
      } catch (error) {
        this.resetProfile()
      } finally {
        this.profileLoaded = true
      }
    },
    resetProfile() {
      const cartStore = useCartStore()
      this.profile = null
      this.profileLoaded = false
      cartStore.resetCart()
    },
    async isProfileLoaded() {
      if (this.profileLoaded) return true

      return new Promise((resolve) => {
        const interval = setInterval(() => {
          if (this.profileLoaded) {
            clearInterval(interval)
            resolve(this.profileLoaded)
          }
        }, 100)
      })
    },
    setSentryUser() {
      if (this.profile) {
        Sentry.setUser({
          id: this.profile.id.toString(),
          email: this.profile.email || undefined,
        })
      }
    },
  },
})
