<template>
  <div v-if="socials" class="socials-icons">
    <a
      v-if="socials.value.twitter_url"
      aria-label="Twitter"
      :href="socials.value.twitter_url"
      rel="nofollow noreferrer"
      target="_blank"
    >
      <SvgIcon name="twitter" />
    </a>
    <a
      v-if="socials.value.facebook_url"
      aria-label="Facebook"
      :href="socials.value.facebook_url"
      rel="nofollow noreferrer"
      target="_blank"
    >
      <SvgIcon name="facebook" />
    </a>
    <a
      v-if="socials.value.instagram_url"
      aria-label="Instagram"
      :href="socials.value.instagram_url"
      rel="nofollow noreferrer"
      target="_blank"
    >
      <SvgIcon name="instagram" />
    </a>
    <a
      v-if="socials.value.youtube_url"
      aria-label="YouTube"
      :href="socials.value.youtube_url"
      rel="nofollow noreferrer"
      target="_blank"
    >
      <SvgIcon name="youtube" />
    </a>
  </div>
</template>

<script setup lang="ts">
import SvgIcon from './SvgIcon.vue'
import { computed } from 'vue'
import { useGlobalStore } from '@/Store/useGlobalStore'
import { SocialSetting } from '@/Types/Setting'

const globalStore = useGlobalStore()

const socials = computed(
  () =>
    globalStore.getSettingByKey('socialsetting') as SocialSetting | undefined,
)
</script>

<style lang="scss">
.socials-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -0.8rem;

  a {
    margin: 0 0.8rem;
    position: relative;
  }

  svg {
    width: 28px;
    height: 28px;
  }
}
</style>
