<template>
  <nav
    aria-label="Main navigation"
    :style="layoutStore.mobileMenuActiveItem ? 'border-bottom: none' : ''"
  >
    <h2
      v-if="isMobile"
      v-show="!layoutStore.mobileMenuActiveItem"
      class="mobile-heading"
    >
      {{ __('header', 'navigation.mobile-navigation-title') }}
    </h2>
    <ul class="top-level-menu" role="menu">
      <li
        v-for="topLevelItem in navigationConfigTranslated"
        :key="topLevelItem.mainTitle + navigationTracker"
      >
        <div class="dropdown-container">
          <TopLevelMenuButton
            v-show="!layoutStore.mobileMenuActiveItem"
            aria-haspopup="true"
            with-chevron
            v-on="getDropdownEvents(topLevelItem)"
          >
            {{ topLevelItem.mainTitle }}
          </TopLevelMenuButton>
          <div
            v-show="layoutStore.mobileMenuActiveItem === topLevelItem.mainTitle"
            aria-label="submenu"
            class="dropdown"
            role="menu"
          >
            <div class="dropdown-inner">
              <div class="main-content">
                <NavigationHeading>{{
                  topLevelItem.mainTitle
                }}</NavigationHeading>
                <ul class="main-block-container" role="menu">
                  <BlockSwitcher
                    v-for="block in topLevelItem.mainBlocks"
                    :key="block.titleBadge.text"
                    :block="block"
                    :style="{
                      gridColumn:
                        block.type === 'TEXTS_WITH_ICONS' && width >= 1400
                          ? 'unset'
                          : '1 / -1',
                    }"
                  />
                </ul>
              </div>
              <div class="sidebar-content">
                <NavigationHeading>{{
                  topLevelItem.sidebarTitle
                }}</NavigationHeading>
                <ul class="sidebar-block-container" role="menu">
                  <BlockSwitcher
                    v-for="block in topLevelItem.sidebarBlocks"
                    :key="block.titleBadge.text"
                    :block="block"
                  />
                </ul>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import navigationConfig from '@/Partials/MainNavigation/navigationConfig'
import { computed, ref } from 'vue'
import NavigationHeading from '@/Partials/MainNavigation/NavigationHeading.vue'
import BlockSwitcher from '@/Partials/MainNavigation/BlockSwitcher.vue'
import { __ } from '@/Helpers/i18n'
import useScreenBreakpoints from '@/Composables/UseScreenBreakpoints'
import { useWindowSize } from '@vueuse/core'
import { useRouter } from 'vue-router'
import TopLevelMenuButton from '@/Partials/MainNavigation/TopLevelMenuButton.vue'
import useLayoutStore from '@/Store/useLayoutStore'

const { width } = useWindowSize()
const { isMobile } = useScreenBreakpoints()
const router = useRouter()
const layoutStore = useLayoutStore()

const recursivelyTranslateObjectProperties = <
  T extends string | number | symbol,
>(
  obj: Record<T, unknown>,
) => {
  for (const key in obj) {
    const item = obj[key]
    if (typeof item === 'string' && item.startsWith('navigation.')) {
      obj[key] = __('header', item)
    } else if (typeof item === 'object' && item) {
      recursivelyTranslateObjectProperties(item)
    }
  }
  return obj
}

const navigationConfigTranslated = computed(
  () =>
    recursivelyTranslateObjectProperties(
      structuredClone(navigationConfig),
      /* since the function only changes the text content of the values, this cast is justified */
    ) as typeof navigationConfig,
)

const getDropdownEvents = (
  topLevelItem: (typeof navigationConfigTranslated.value)[number],
) =>
  isMobile.value
    ? {
        click() {
          if (layoutStore.mobileMenuActiveItem === topLevelItem.mainTitle) {
            layoutStore.mobileMenuActiveItem = null
          } else {
            layoutStore.mobileMenuActiveItem = topLevelItem.mainTitle
          }
        },
      }
    : {
        mouseleave(e: MouseEvent) {
          ;(e.target as HTMLButtonElement).blur()
        },
        mouseover(e: MouseEvent) {
          ;(e.target as HTMLButtonElement).focus()
        },
      }

/* cannot use route.fullPath as template element key because navigation must be closed even if the user tries to navigate to the same page */
const navigationTracker = ref(0)
router.beforeEach(() => {
  navigationTracker.value++
})
</script>

<style scoped lang="scss">
nav {
  @include mobile-only {
    padding: 3.2rem 2rem 4rem 2rem;
    margin: 0 -1.6rem;
    border-bottom: 6px solid $gray-4;
  }
  @include desktop-only {
    padding: 1.2rem 0;
  }
}

.top-level-menu {
  display: flex;
  flex-direction: column;
  @include desktop-only {
    flex-direction: row;
  }
}

.dropdown {
  @include mobile-only {
    margin: -3.2rem -2rem -4rem -2rem;
  }

  @include desktop-only {
    position: absolute;
    overflow-y: auto;
    z-index: 11;
    display: none;
    padding-top: 1.2rem;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 2 * 0.8rem);
  }

  @media (min-width: 1200px) {
    width: calc(min(1440px, 100%) - 2 * 14.6rem);
  }
}

@include desktop-only {
  .dropdown-container {
    &:hover,
    &:focus-within {
      .dropdown {
        display: block !important;
      }

      :deep(.top-level-menu-button) {
        background: $gray-4;
      }
    }
  }
}

.dropdown-inner {
  background: $white;
  border-radius: 40px;
  display: flex;
  flex-direction: column;

  @include desktop-only {
    margin-top: 1rem;
    flex-direction: row;
  }
}

.main-content {
  padding: 3.2rem 1.6rem 4rem 1.6rem;

  @include desktop-only {
    padding: 5.6rem 6rem 6.8rem 6.8rem;
    flex: 1 1 71.2rem;
  }
}

.main-block-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4rem 2.4rem;
}

.sidebar-content {
  padding: 2.8rem 1.6rem 6.4rem 1.6rem;
  border-top: 6px solid $gray-4;

  @include desktop-only {
    margin: 0.8rem 0.8rem 0.8rem;
    padding: 4.8rem 5.2rem;
    border-radius: 32px;
    background: $gray-4;
    flex: 1 1 42rem;
  }
}

.sidebar-block-container {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  @include desktop-only {
    gap: 4.8rem;
  }
}

.mobile-heading {
  letter-spacing: -0.04em;
  margin-bottom: 2.8rem;
  font-weight: 500;
  line-height: 1.1;
  font-size: 2.4rem;
  margin-left: 0;
}
</style>
