<template>
  <FaqSection />
  <div :class="{ 'with-seo-block': footerStore.showSeoSection }">
    <SeoFooterSection />
    <FaqBlock />
    <footer class="site-footer">
      <div class="content-wrapper">
        <div class="top">
          <div class="container">
            <div class="left">
              <div class="logos-desktop">
                <OptimizedImg
                  alt=""
                  :size="{ height: 27, width: 164 }"
                  src="logo-white.svg"
                />
                <div class="logo-wrap">
                  <OptimizedImg
                    :alt="__('alt-tags', 'Eco cert')"
                    :size="{ height: 40, width: 56 }"
                    src="eco-cert-gray.svg"
                  />
                  <OptimizedImg
                    :alt="__('alt-tags', 'Bureau veritas')"
                    :size="{ height: 44, width: 35 }"
                    src="bureau-veritas-gray.svg"
                  />
                </div>
              </div>
              <div class="footer-socials">
                <p>{{ __('footer', 'Follow us') }}</p>
                <SocialsIcons />
              </div>
              <div class="footer-email">
                <p>{{ __('footer', 'Contact us via Email:') }}</p>
                <a
                  v-if="supportBlockSettings"
                  :href="`mailto:${supportBlockSettings.value.email_block_email}`"
                >
                  {{ supportBlockSettings.value.email_block_email }}
                </a>
              </div>
            </div>
            <div v-if="menuWithPlaceholders" class="right">
              <div
                v-for="item in menuWithPlaceholders"
                :key="item.id"
                class="block"
              >
                <template v-if="'title' in item">
                  <Link class="title" :url="item.url">{{ item.title }}</Link>
                  <ul v-if="item.children">
                    <li v-for="i in item.children" :key="i.id">
                      <Link v-if="i.url" :url="i.url">{{ i.title }}</Link>
                    </li>
                  </ul>
                </template>
              </div>
            </div>
            <div class="logos-mobile">
              <OptimizedImg
                alt=""
                :size="{ height: 27, width: 164 }"
                src="logo-white.svg"
              />
            </div>
          </div>
        </div>
        <div class="bottom">
          <div class="container">
            <p>
              {{ __('footer', 'All rights reserved — Selfnamed') }}
              {{ new Date().getFullYear() }}
            </p>
            <p class="address">
              {{ __('footer', 'Kauguru 6, Riga, Latvia, LV1046') }}
            </p>
            <p>
              {{ __('footer', 'Trademark registration number: 018591582') }}
            </p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script setup lang="ts">
import SocialsIcons from '@/Partials/SocialsIcons.vue'
import FaqBlock from '@/Components/FaqBlock.vue'
import Link from '@/Partials/Link.vue'
import { __ } from '@/Helpers/i18n'
import { useQuery } from 'vue-query'
import { getFooterLinks } from '@/Api/Global'
import { computed } from 'vue'
import { useGlobalStore } from '@/Store/useGlobalStore'
import { SupportBlockSetting } from '@/Types/Setting'
import { listToTree } from '@/Helpers/TreeHelper'
import SeoFooterSection from '@/Components/SeoFooterSection.vue'
import useFooterStore from '@/Store/useFooterStore'
import FaqSection from '@/Components/FaqSection.vue'
import OptimizedImg from '@/Partials/ui/OptimizedImg.vue'
import { FooterLinkResource } from '@/Types/FooterLink'

const globalStore = useGlobalStore()
const footerStore = useFooterStore()

const { data: menu } = useQuery('footer-menu', async () => {
  return listToTree(
    (await getFooterLinks()) as Array<FooterLinkResource & { slug: string }>,
    'parent_id',
  )
})

// add placeholders to menu so that the last column is always right aligned if it has less than 3 items
const menuWithPlaceholders = computed(() => {
  if (!menu.value) return
  const menuCopy: Array<FooterLinkResource | { id: `placeholder-${number}` }> =
    menu.value.slice(0, 5)
  const cols = 3
  const reminder = menuCopy.length % cols
  if (!reminder) return menuCopy
  const placeholders = cols - reminder
  for (let i = 0; i < placeholders; i++) {
    menuCopy.splice(menuCopy.length - reminder, 0, {
      id: `placeholder-${i}`,
    })
  }
  return menuCopy
})

const supportBlockSettings = computed(
  () =>
    globalStore.getSettingByKey('supportblocksetting') as
      | SupportBlockSetting
      | undefined,
)
</script>

<style lang="scss">
.with-seo-block {
  border-radius: 4rem 4rem 0 0;
  background: $gray-6;
  @include desktop-only {
    border-radius: 8rem 8rem 0 0;
  }
}
.site-footer {
  padding: 2rem;

  @include desktop-only {
    padding: 1.4rem 2.8rem 2rem 2.8rem;
    border-radius: 60px;
  }

  .content-wrapper {
    overflow: hidden;
    border-radius: 3.4rem;
    @include desktop-only {
      border-radius: 6rem;
    }
  }

  .container {
    max-width: 1210px;
    display: flex;
    padding: 0 2.8rem;
  }

  .top {
    background: $black;
    line-height: 160%;
    padding: 4.8rem 0 6rem 0;
    @include desktop-only {
      padding: 8rem 0 8.8rem 0;
    }

    .container {
      align-items: flex-start;
      flex-direction: column-reverse;
      @include desktop-only {
        flex-direction: row;
      }
    }
  }

  .left {
    flex: none;
  }

  .right {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 3.6rem;
    justify-content: space-between;
    width: 100%;

    @include desktop-only {
      margin-left: auto;
      max-width: 630px;
      grid-column-gap: 8.8rem;
      grid-template-columns: repeat(3, 1fr);
      grid-row-gap: 8.4rem;
      grid-auto-flow: row;
    }

    .title {
      color: $gray-9;
      margin-bottom: 1.2rem;
      font-size: 1.2rem;
      display: inline-block;
      @include desktop-only {
        margin-bottom: 1.6rem;
      }
    }

    ul {
      font-size: 1.4rem;
      li {
        margin-bottom: 1.2rem;
        @include desktop-only {
          margin-bottom: 0.8rem;
        }
      }

      a {
        color: $white;
        white-space: nowrap;
      }
    }
  }

  .logos-desktop {
    display: none;
    @include desktop-only {
      display: block;
      padding-right: 2rem;
    }
  }

  .logos-mobile {
    margin-bottom: 4.8rem;

    @include desktop-only {
      display: none;
    }
  }

  .logo-wrap {
    display: flex;
    align-items: center;
    margin: 3rem 0 0 0;

    img {
      margin-right: 2rem;
    }
  }

  .footer-socials {
    margin-top: 3.6rem;
    @include desktop-only {
      margin-top: 15.4rem;
    }

    p {
      color: $gray-9;
      font-size: 1.2rem;
      margin-bottom: 1rem;
    }
  }

  .footer-email {
    margin-top: 4.8rem;
    @include desktop-only {
      margin-top: 9.6rem;
    }

    p {
      font-size: 1.2rem;
      margin-bottom: 1.2rem;
      color: $gray-9;
    }

    a {
      color: $white;
    }
  }

  .socials-icons {
    justify-content: flex-start;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .bottom {
    background: $black;
    color: $gray-9;
    border-top: 1px solid $gray-7;
    font-size: 1.2rem;
    padding: 4rem 0 6rem 0;
    @include desktop-only {
      padding: 3.2rem 0;
    }

    .container {
      flex-direction: column;
      padding: 0 2.8rem;
      @include desktop-only {
        flex-direction: row;
      }
    }

    p:not(:last-child) {
      margin-bottom: 1.2rem;
      @include desktop-only {
        margin-bottom: 0;
      }
    }

    .address {
      @include desktop-only {
        margin-left: 308px;
        margin-right: 5.6rem;
      }
    }
  }
}
</style>
