<template>
  <div class="forgot-password">
    <h5>{{ __('profile', 'Forgot password') }}</h5>
    <p class="description">
      {{ __('profile', "What's your registration email?") }}
    </p>
    <form v-if="inputs" action="" @submit.prevent="updateProfile">
      <div
        v-for="item in inputs"
        :key="item.name"
        class="input-element"
        :class="{ error: findErrorField(errors, item.name) }"
      >
        <div class="wrap">
          <input
            :id="item.name"
            v-model="item.value"
            placeholder=" "
            :type="item.type"
          />
          <label :for="item.name">{{ item.label }}</label>
        </div>

        <div v-if="errors" class="errors">
          <div v-for="i in errors" :key="i.field">
            <p v-if="i.field === item.name">{{ i.message }}</p>
          </div>
        </div>
      </div>
      <div class="button-wrap">
        <button class="btn btn-primary" :class="{ loading }" type="submit">
          <span>{{ __('global', 'Confirm') }}</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { getAcceptSocialInputs } from '@/Helpers/Forms'
import { findErrorField } from '@/Helpers/Global'
import { __ } from '@/Helpers/i18n'
import { postPasswordReset } from '@/Api/Registration'

const emit = defineEmits<{
  'switch-to-success': [data: { title: string; description: string }]
}>()

const inputs = ref<any>(getAcceptSocialInputs())
const errors = ref()
const loading = ref(false)

const updateProfile = async () => {
  loading.value = true
  errors.value = null
  const data: any = {}

  inputs.value.forEach((item: any) => {
    data[item.name] = item.value
  })

  await postPasswordReset(data).catch((error: any) => {
    errors.value = error.response.data
  })

  loading.value = false

  if (!errors.value) {
    emit('switch-to-success', {
      title: 'Check your email',
      description: 'We have sent you password reset link',
    })

    inputs.value.forEach((item: any) => {
      item.value = null
    })
  }
}
</script>

<style lang="scss">
.forgot-password {
  h5 {
    text-align: center;
    margin-bottom: 1.6rem;
  }

  .description {
    text-align: center;
    margin-bottom: 2.4rem;
  }
}
</style>
