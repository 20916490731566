<template>
  <Link
    v-if="actionType === 'LINK'"
    v-bind="$attrs"
    :class="{ reverse: icon?.position === 'right' }"
    :url="url ?? ''"
  >
    <SvgIcon v-if="icon" :name="icon.name" />
    {{ text }}
  </Link>

  <button
    v-else
    v-bind="$attrs"
    :class="{ reverse: icon?.position === 'right' }"
    @click="handelButtonClick"
  >
    <SvgIcon v-if="icon" :name="icon.name" />
    {{ text }}
  </button>

  <Teleport v-if="isVideoModalOpen" to="body">
    <VideoModal
      :open="isVideoModalOpen"
      :url="url ?? ''"
      @close="isVideoModalOpen = false"
    />
  </Teleport>

  <Teleport v-if="isSignUpModalOpen" to="body">
    <SignUpModal
      v-if="signupBackUrl"
      :login="false"
      :open="isSignUpModalOpen"
      :redirect-url="router.resolve(urlToDropshippingStores).fullPath"
      @close="isSignUpModalOpen = false"
    />
  </Teleport>
</template>

<script setup lang="ts">
import SvgIcon from '@/Partials/SvgIcon.vue'
import Link from '@/Partials/Link.vue'
import VideoModal from '@/Components/VideoModal.vue'
import { computed, ref } from 'vue'
import { withLang } from '@/routes'
import { useRouter } from 'vue-router'
import { useLoginStore } from '@/Store/useLoginStore'
import SignUpModal from '@/Components/SignUpModal.vue'
import { ActionElementActionType } from '@/Types/ActionElement'

const props = defineProps<{
  actionType: ActionElementActionType
  text: string
  url?: string | null
  icon?: {
    position: 'left' | 'right'
    name: string
  }
}>()

const router = useRouter()
const loginStore = useLoginStore()

const isVideoModalOpen = ref(false)
const isSignUpModalOpen = ref(false)

const signupBackUrl = computed(() => router.currentRoute.value.fullPath)
const urlToDropshippingStores = computed(() =>
  withLang({ name: 'dropshipping-stores' }),
)

const connectStore = () => {
  if (loginStore.userLoggedIn) {
    router.push(urlToDropshippingStores.value)
  } else {
    isSignUpModalOpen.value = true
  }
}

function handelButtonClick() {
  if (props.actionType === 'VIDEO_MODAL') {
    isVideoModalOpen.value = true
  } else {
    connectStore()
  }
}
</script>

<style scoped lang="scss">
.link {
  display: inline-flex;
  align-items: center;
  gap: 0.4rem;
  text-decoration: underline;
  color: $black;
  @include desktop-only {
    font-weight: 500;
    font-size: 1.8rem;
  }
}
.btn {
  gap: 0.8rem;
  border-width: 0.2rem;

  svg {
    height: 14px;
    aspect-ratio: 1;
  }
}

.btn-secondary {
  background: transparent;
}

.reverse {
  flex-direction: row-reverse;
}
</style>
