import axios from 'axios'
import useLangStore from '@/Store/useLangStore'

export const patchProfile = (data: any) => {
  const lang = useLangStore().getLang()

  return axios.patch(`/${lang}/profile`, data)
}

export const setProfile = () => {
  const lang = useLangStore().getLang()

  return axios.get(`/${lang}/profile`)
}

export const logout = () => {
  const langStore = useLangStore()
  return axios.post(`/${langStore.getLang()}/logouts`)
}

export const facebookLogin = (token: string) => {
  return axios.post(`/${useLangStore().getLang()}/facebook-logins`, {
    access_token: token,
  })
}

export const googleLogin = (token: string) => {
  return axios.post(`/${useLangStore().getLang()}/google-logins`, {
    token_id: token,
  })
}

export const login = (data: Record<string, unknown>) => {
  const langStore = useLangStore()
  return axios.post(`/${langStore.getLang()}/logins`, data)
}
