<template>
  <div v-if="open" class="modal">
    <div class="overlay" @click="closeModal"></div>
    <div class="content">
      <button class="close-modal" @click="closeModal">
        <SvgIcon name="exit" />
      </button>
      <iframe
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        frameborder="0"
        height="414"
        :src="url"
        title="YouTube video player"
        width="719"
      ></iframe>
    </div>
  </div>
</template>

<script setup lang="ts">
import SvgIcon from '@/Partials/SvgIcon.vue'

defineProps<{ open: boolean; url: string }>()

const emit = defineEmits<{
  close: []
  'middle-scrolled': [event: Event]
}>()

const closeModal = () => emit('close')
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: center;

  .overlay {
    background-color: rgba(21, 20, 26, 0.7);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border: none;
  }

  .content {
    margin: 0 1.6rem 0 1.6rem;
    background: transparent;
    position: relative;
    max-width: 719px;
    min-height: 18.5rem;
    width: 100%;
  }

  iframe {
    box-shadow: 0 20px 48px rgba(6, 24, 68, 0.1);
    width: 100%;
    height: 100%;
    aspect-ratio: 16 / 9;
    background: rgba(0, 0, 0, 0.6);
    @include desktop-only {
      height: 41.4rem;
    }
  }

  .close-modal {
    position: absolute;
    top: - calc(2.4rem + 1.6rem);
    right: 0;
    cursor: pointer;
    color: white;
    z-index: 1;
    background: transparent;
    border: none;

    svg {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
}
</style>
