export default [
  {
    path: '/:lang/private-adyen-test-page',
    component: () => import('./Pages/PrivateAdyenTestPage.vue'),
    name: 'private-adyen-test-page',
    strict: true,
  },
  {
    path: '/:lang/private-view-order-page/:nr',
    component: () => import('./Pages/PrivateViewOrderPage.vue'),
    name: 'private-view-order-page',
    strict: true,
    props: true,
  },
]
