import { SiteTreeSectionResource } from '@/Types/SiteTreeSection'
import { FooterLinkResource } from '@/Types/FooterLink'

const generateNodesWithSlugs = <
  T extends Pick<
    SiteTreeSectionResource | (FooterLinkResource & { slug: string }),
    'slug' | 'children'
  >,
>(
  nodes: T[],
  slug = '',
): Array<T & { slug: string }> => {
  nodes.forEach((node) => {
    node.slug = `${slug}/${node.slug}`
    if (node.children) {
      generateNodesWithSlugs(node.children as any, (node as any).slug)
    }
  })
  return nodes
}
export const listToTree = <
  T extends
    | SiteTreeSectionResource
    | SiteTreeSectionResource<'section_best_seller_config'>
    | (FooterLinkResource & { slug: string }),
>(
  list: T[],
  parentAttribute: keyof T,
  generateSlugs = true,
): T[] => {
  const map = list.reduce<Record<string, number>>((acc, item, index) => {
    acc[item.id] = index
    item.children = []
    return acc
  }, {})

  const roots = list.reduce<T[]>((acc, node) => {
    if (node[parentAttribute]) {
      const parentNode = list[map[node[parentAttribute] as keyof typeof map]]
      if (parentNode) {
        parentNode.children = parentNode.children || []
        parentNode.children.push(node as any)
      }
    } else {
      acc.push(node)
    }
    return acc
  }, [])

  if (generateSlugs) return generateNodesWithSlugs(roots)

  return roots
}
