import { defineStore } from 'pinia'
import { getUnreadOrdersCount } from '@/Api/Order'

const timeToShowPopup = 4000

export const useProfileStore = defineStore('profile', {
  state: (): {
    profileUnreadOrdersCount: number
    popupConfig: { message: string; type: 'success' | 'error' | 'info' } | null
    profileScrollingDisabled: boolean
  } => ({
    profileUnreadOrdersCount: 0,
    popupConfig: null,
    profileScrollingDisabled: false,
  }),
  actions: {
    async setProfileUnreadOrdersCount() {
      this.profileUnreadOrdersCount = await getUnreadOrdersCount()
    },
    showPopup(config: typeof this.popupConfig) {
      this.popupConfig = config
      setTimeout(() => (this.popupConfig = null), timeToShowPopup)
    },
    showErrorPopup(message = 'Something went wrong...') {
      this.showPopup({ message, type: 'error' })
    },
    showSuccessPopup(message: string) {
      this.showPopup({ message, type: 'success' })
    },
    showInfoPopup(message: string) {
      this.showPopup({ message, type: 'info' })
    },
    disableScrolling() {
      this.profileScrollingDisabled = true
    },
    enableScrolling() {
      this.profileScrollingDisabled = false
    },
  },
})
