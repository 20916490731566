import axios from 'axios'
import useLangStore from '@/Store/useLangStore'
import { FaqResource } from '@/Types/Faq'

export const getFaqs = async (sectionId: number | null = null) => {
  const langStore = useLangStore()

  const params: Record<string, unknown> = {}
  if (sectionId) {
    params['filter[faq_block_id]'] = sectionId
  }

  return (
    await axios.get<FaqResource[]>(`/${langStore.getLang()}/faqs`, {
      params,
    })
  ).data
}
