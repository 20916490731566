<template>
  <div class="payment-method-icons">
    <div v-for="(item, index) in icons" :key="index" class="icon">
      <OptimizedImg :alt="item" :size="{ height: 26, width: 40 }" :src="item" />
    </div>
  </div>
</template>

<script setup lang="ts">
import OptimizedImg from '@/Partials/ui/OptimizedImg.vue'

const icons = [
  'payment-methods/visa.svg',
  'payment-methods/mastercard.svg',
  'payment-methods/paypal.svg',
  'payment-methods/visa-electron.svg',
]
</script>

<style lang="scss" scoped>
.payment-method-icons {
  display: flex;
  gap: 0.6rem;
  justify-content: center;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $light-gray;
  @include border-radius(md);
  height: 2.8rem;
  width: 4rem;
  background: white;
  padding: 0.6rem;
}
</style>
