import { Error } from '@/Types/Error'

export type ErrorData = {
  response?: {
    data?: Error[]
    status: number
  }
}
export const setErrors = (payload: ErrorData) => {
  return payload?.response?.data ?? null
}
